import React from 'react'
import {AppBar, Button, styled, Toolbar, Typography} from "@mui/material";
import {Link} from "react-router-dom";

const MainBar = styled(AppBar)(({theme}) => ({
    marginBottom: theme.spacing(2)
}))

const Logo = styled(Typography)(({theme}) => ({
    marginRight: theme.spacing(1),
    color: 'inherit'
}))

const JAppBar = () => {
    return (
        <MainBar position="static">
            <Toolbar variant="dense">
                <Logo variant="h6">
                    Dorr
                </Logo>
                <Button component={Link} to="/">
                    Inventory
                </Button>
                <Button component={Link} to="/products">
                    Products
                </Button>
            </Toolbar>
        </MainBar>
    )
}

export default JAppBar
