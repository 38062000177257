import React from 'react';
import {
    styled,
    ThemeProvider,
    createTheme, Container,
} from "@mui/material";
import JAppBar from "./components/JAppBar";
import {BrowserRouter as Router, Route} from "react-router-dom";
import Inventory from "./views/Inventory";

const Wrapper = styled("div")(() => ({
    width: '100vw',
    height: '100vh',
    margin: 'auto',
    backgroundColor: '#7e7e7e'
}))

const app = () => {
    const mainTheme = createTheme({
        palette: {
            mode: 'dark'
        }
    })

    return (
        <Router>
            <ThemeProvider theme={mainTheme}>
                <Wrapper>
                    <JAppBar/>
                    <main>
                        <Container>
                            <Route path="/">
                                <Inventory/>
                            </Route>
                        </Container>
                    </main>
                </Wrapper>
            </ThemeProvider>
        </Router>
    )
}

export default app
