import * as t from 'io-ts'

const runtimeDecoder = t.type({
    backendHost: t.string
})

export type AppConfig = t.TypeOf<typeof runtimeDecoder>

const loadConfig = () => runtimeDecoder.decode((window as any).__runtime)

export default loadConfig
